import { Button } from '@mui/material'
import { DialogSuccessPaymentImage } from '../dialogSuccessPaymentImage'
import { DialogCancelPaymentImage } from '../dislogCancelPayment'
import { DialogFastDownload } from '../dialogFastDownloadGuest'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import { useEffect, useState } from 'react'
import instance from '../../../providers/axios.config'
import Notiflix from 'notiflix'
import { BASE_URL } from '../../../utils/constants'
type typeImageForDownload = {
  type?: string
  disabled?: boolean
  assetId?: any,
  category?: string
  size?: any
}

export const BuyFastDownload = (props: typeImageForDownload) => {
  const { t } = useTranslation()
  const { assetName } = useParams()
  const {assetId, category, size, disabled} = props;
  const { search } = useLocation()
  const user = useSelector((state: RootState) => state.user.usersData)
  const data = new URLSearchParams(search)
  const [openSuccessPayment, setOpenSuccessPayment] = useState(false)
  const [openCancelPayment, setOpenCancelPayment] = useState(false)
  const [openModalFastDownload, setOpenFastDownload] = useState(false)

  useEffect(() => {
    if (data?.get('size') && (data.get('session_id') || (data.get('token') && data.get('PayerID')))) {
      if (data?.get('success')) {
        setOpenSuccessPayment(true)
      } else if (data?.get('cancel')) {
        setOpenCancelPayment(true)
      }

    }
  }, [data.get('session_id'), data.get('PayerID')])

  const handleFastDownload = async () => {
    if (size) {
      setOpenFastDownload(true)
    } else {
      Notiflix.Report.info(
        'Info',
        'Please Select size for image',
        'OK'
      )
    }
  }

  const handleCloseFastDownload = () => {
    setOpenFastDownload(false)
  }
  const handleCloseSuccessPayment = () => {
    setOpenSuccessPayment(false)
  }
  const handleCloseCancelPayment= () => {
    setOpenCancelPayment(false)
  }

  const handleSubmitPayment = async (data: any) => {

    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const dataPayloadBilling = {
        name: `${data?.firstName} ${data?.lastName}`,
        phone_number: data?.phone_number,
        email: data?.email,
        address: data?.address,
        city: data?.city,
        zip: data?.zip,
        country_id: data?.country,
        state: data?.state,
        vat_number: data?.vatNumber
      }
      const dataPayload = {
        url: `${BASE_URL}assets/${assetName}/buy?plan=fast-download&category=${category}&size=${size}`,
        image_id: assetId,
        payment_method: data?.payment,
        image_size_id: size?.toString(),
        category: category,
        method: 'fast-download',
        billing_address: dataPayloadBilling,
      }
      const dataEditPaylod = {
        url: `${BASE_URL}assets/${assetName}/buy?plan=fast-download&category=${category}&size=${size}`,
        image_id: Number(assetId),
        image_size_id: Number(size),
        payment_method: data?.payment,
        category: category
      }
      if(user && data?.edit) {
        await instance.put(`user/profile`, JSON.stringify({...dataPayloadBilling, first_name: data?.firstName, last_name: data?.lastName }))
      }
      const response = await instance.post(`subscriptions/fast-download`, data?.edit ? { ...dataPayload } :{ ...dataEditPaylod }) as any;
      setOpenFastDownload(false)
      if(data.payment === 'paypal' && response?.links?.length) {
        window.open(response?.links[1].href, '_self')
      }
      if (response && typeof response === 'string' && data.payment === 'stripe') {
        window.open(response, '_self')
      }
      Notiflix.Loading.remove()
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }

  return (
    <>
      <Button
        disabled={disabled}
        variant='contained'
        onClick={handleFastDownload}
        sx={{
          '&:hover': {
            backgroundColor: '#c52d2f'
          }
        }}
      >
        {t('download_image')}
      </Button>
      {openSuccessPayment 
        ? (
          <DialogSuccessPaymentImage open={openSuccessPayment} handleClose={handleCloseSuccessPayment}
            dataPayload={{
              image_id: Number(assetId),
              method: 'fast-download',
            }}
          />
        ) : null}
      {openCancelPayment? <DialogCancelPaymentImage open={openCancelPayment} handleClose={handleCloseCancelPayment} /> : null}
      {openModalFastDownload ? <DialogFastDownload open={openModalFastDownload} handleOpen={handleCloseFastDownload}
                                                   handleConfirm={handleSubmitPayment} /> : null}
    </>
  )
}
