import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip, Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { CustomPagination } from '../../../../../../shared-components/pagination/CustomPagination'
import { CustomTextfield } from '../../../../../../shared-components/textfield/CustomTextField'
import { Title } from '../../Title'
import styles from './Downloads.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import Skeleton from '@mui/material/Skeleton'
import Comment from './Comment';
import { ICategory, IDownload, ILicense, ISize } from '../../../../../../../utils/types'

export const Downloads = () => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [selectedLicense, setSelectedLicense] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [selectedSize, setSelectedSize] = useState(0)

  const [isLoading, setIsLoading] = useState(false)
  const [myDownloads, setMyDownloads] = useState<IDownload[]>([])
  const [licenses, setLicenses] = useState<ILicense[]>([])
  const [categories, setCategories] = useState<ICategory[]>([])
  const [sizes, setSizes] = useState<ISize[]>([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    totalPages: 1
  })
  const isInitialFilters = selectedCategory === 0 && selectedLicense === 0 && !toDate && !fromDate && !searchValue;
  const handleChange = (event: any, value: number) => {
    setPagination({...pagination, currentPage: value})
  }
  const getMyFilters = async () => {
    try{
      const responseLicenses = await instance.get(`my-downloads/licenses`)
      const responseCategories = await instance.get(`my-downloads/image-categories`)
      setLicenses(responseLicenses?.data)
      setCategories(responseCategories?.data)
    } catch (e) {

    }
  }
  const getMyDownloadsFirst = async () => {
    setIsLoading(true)
    try {
      const res = await instance.get(`my-downloads?limit=10&page=${pagination.currentPage}`)
      setMyDownloads(res?.data?.downloads || [])
      setPagination({ ...res?.data?.pagination })
      setIsLoading(false)
    } catch (error) {

      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  const getMyDownloads= async () => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    try {
      const search = searchValue ? `&q=${searchValue}` : '';
      const dateFrom = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
      const dateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      const constructUrlDate = dateFrom && dateTo  ? `&from=${dateFrom}&to=${dateTo}` : ''
      const constructFilters = `${selectedCategory !== 0 ? `&category_id=${selectedCategory}` : ''}${selectedLicense !== 0 ? `&license_id=${selectedLicense}` : ''}${selectedSize !==0 ? `&license_size_id=${selectedSize}` : ''}`
      const res = await instance.get(`my-downloads?limit=10&page=${pagination.currentPage}${constructFilters}${constructUrlDate}${search}`)
      setMyDownloads(res?.data?.downloads || [])
      setPagination({ ...res?.data?.pagination })
      Notiflix.Loading.remove()
    } catch (error) {
      Notiflix.Loading.remove()
      // Show error notification
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch data. Please try again later.',
        'OK'
      )
    }
  }
  useEffect(() => {
    getMyDownloadsFirst()
    getMyFilters()
  }, [pagination.currentPage])

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchValue(event.target.value)
  }
  const selectLicense = async (e: any) => {
    setSelectedLicense(e.target.value)
    if(e.target.value !== 0) {
      try {
        const responseSizes = await instance.get(`my-downloads/license/${e.target.value}/sizes`)
        setSizes(responseSizes.data)
      } catch (e){

      }
    }
  }
  const handleSearchDownload= async() => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    await getMyDownloads()
    Notiflix.Loading.remove()
  }
  const handleSaveComment = async (comment: string | null, idDownload: number | null) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const dataPayload = {
        comment: comment,
      }
      await instance.post(`my-downloads/${idDownload}/comment`, {...dataPayload})
      await getMyDownloads()
      Notiflix.Loading.remove()

    } catch (e) {
      Notiflix.Loading.remove()
    }
  }
  const handleDownload= async (linkDownload: string, name: string, category: string) => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const res = await instance.get(`${linkDownload}`, { responseType: 'arraybuffer' }) as any
      let blob = new Blob([res], { type: `image/${category}` }),
        url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${name}.${category}`)
      document.body.appendChild(link)
      link.click()
      await getMyDownloads()
      Notiflix.Loading.remove()

    } catch (e) {
      Notiflix.Loading.remove()
    }
  }
  if(!isLoading && !myDownloads?.length && isInitialFilters) {
    return (
      <div className={styles.container}>
        <div className={styles.top}>
          <Title title={t('My Downloads')} />
        </div>
        <div className={styles.content}>
          {t('no_downloads_images')}
        </div>
      </div>
    )
  }
  const renderSkeletonData = () => {
    return (
      <>
      <TableBody>
        {[1, 2, 3, 4, 5].map((number) => (
          <TableRow key={number} className={styles.tableRow}>
            <TableCell component='th' scope='row'>
              <Skeleton>
                <Avatar
                  alt={'avatar'}
                  variant='rounded'
                  src={''}
                />
              </Skeleton>
            </TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell><Skeleton /></TableCell>
            <TableCell>
              <div className={styles.cellContainer}>
                <div><Skeleton /></div>
                <Skeleton />
              </div>
            </TableCell>
            <TableCell className={styles.cell}>
              <div className={styles.cellContainer}>
                <Skeleton />

                <Skeleton />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      </>
    )
  }


  const renderTableData = () => {
    if(!myDownloads?.length && !isInitialFilters) {
      return renderNoResultsFound()
    }
    return (
      <>
        <TableBody>
          {(myDownloads || []).map((download) => (
            <TableRow key={download?.id} className={styles.tableRow}>
              <TableCell component='th' scope='row'>
                <Avatar
                  alt={download?.image?.title}
                  variant='rounded'
                  src={download?.image?.thumbURL}
                />
              </TableCell>
              <TableCell>{download?.image?.id}</TableCell>
              <TableCell>
                <Tooltip title={download?.image?.title} placement="top-start">
                  <Typography noWrap className={styles.ellipsis}>{download?.image?.title}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{download?.license?.name}</TableCell>
              <TableCell>{download?.category?.name?.toUpperCase()}</TableCell>
              <TableCell>{download?.licenseSize?.name}</TableCell>
              <TableCell>{moment(download?.createdAt).format('DD-MM-YYYY')}</TableCell>
              <TableCell>
                <div className={styles.cellContainer}>
                  <div>{download?.downloads}(10)</div>

                  <Button
                    disabled={download.downloads >= 10}
                    variant='contained'
                    className={download.downloads < 10 ? styles.button : styles.buttonDisabled}
                    onClick={() => handleDownload(download.download_link, download?.image?.title, download?.category?.name)}
                  >
                    {t('Download')}
                  </Button>
                </div>
              </TableCell>
              <TableCell className={styles.cell}>
                <Comment idDownload={download.id} comment={download?.comment} onSaveComment={handleSaveComment}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </>
    )
  }
  const renderNoResultsFound = () => {
    return (
      <>
        <TableBody>
          <TableRow>
            <TableCell colSpan={9}>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">{t('no_results_found')}</Alert>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <Title title={t('My Downloads')} />
      </div>

      <div className={styles.content}>
        <div className={styles.topContent}>
          <CustomTextfield
            className={styles.searchBar}
            size='small'
            placeholder={t('Image ID, Title, Keywords')}
            value={searchValue}
            onChange={handleSearchChange}
          />

          <CustomTextfield
            select
            size='small'
            label={t('License')}
            variant='outlined'
            value={selectedLicense}
            onChange={(e: any) => selectLicense(e)}
          >
            <MenuItem value={0}>{t('All')}</MenuItem>
            {licenses.map((license) => (
              <MenuItem value={license?.id}>{license?.name}</MenuItem>
            ))}
          </CustomTextfield>

          <CustomTextfield
            select
            size='small'
            // disabled={!selectedLicense || Number(selectedLicense) === 0}
            label={t('Size')}
            variant='outlined'
            value={selectedSize}
            onChange={(e: any) => setSelectedSize(e.target.value)}
          >
            <MenuItem value={0}>{t('All')}</MenuItem>
            {sizes.map((size) => (
              <MenuItem value={size.id}>{size?.name}</MenuItem>
            ))}
          </CustomTextfield>
          <CustomTextfield
            select
            size='small'
            label={t('Categories')}
            variant='outlined'
            value={selectedCategory}
            onChange={(e: any) => setSelectedCategory(e.target.value)}
          >
            <MenuItem value={0}>{t('All')}</MenuItem>
            {categories.map((category) => (
              <MenuItem value={category.id}>{category.name.toUpperCase()}</MenuItem>
            ))}
          </CustomTextfield>


          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.textfield}
              label={t('From Date')}
              value={fromDate}
              disableFuture
              format='dd-MM-yyyy'
              onChange={(newValue: any) => {
                setFromDate(newValue)
              }}
              slots={{
                textField: CustomTextfield,
              }}
              slotProps={{
                textField: { size: 'small' },
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={styles.textfield}
              label={t('To Date')}
              value={toDate}
              disableFuture
              format='dd-MM-yyyy'
              onChange={(newValue: any) => {
                setToDate(newValue)
              }}
              slots={{
                textField: CustomTextfield,
              }}
              slotProps={{
                textField: { size: 'small' },
              }}
            />
          </LocalizationProvider>

          <IconButton
            onClick={handleSearchDownload}
            sx={{
              backgroundColor: '#c11b1e',
              '&:hover': {
                backgroundColor: '#c52d2f',
              },
            }}
          >
            <SearchIcon style={{ color: 'white' }} />
          </IconButton>
        </div>

        <div className={styles.table}>
          <TableContainer component={Paper} className={styles.table}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableRow}>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell align='left'>{t('Image ID')}</TableCell>
                  <TableCell align='left'>{t('Title')}</TableCell>
                  <TableCell align='left'>{t('License')}</TableCell>
                  <TableCell align='left'>{t('Category')}</TableCell>
                  <TableCell align='left'>{t('Size')}</TableCell>
                  <TableCell align='left'>{t('Date')}</TableCell>
                  <TableCell align='left'>{t('Used Downloads')}</TableCell>
                  <TableCell align='left'>{t('My Comments')}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? renderSkeletonData() : renderTableData()}
            </Table>
          </TableContainer>

          {pagination?.totalPages > 1 && <CustomPagination
            shape='rounded'
            count={pagination?.totalPages}
            page={pagination?.currentPage}
            onChange={handleChange}
            size='large'
            className={styles.pagination}
          />}
        </div>
      </div>
    </div>
  )
}
