import {
  Box,
  Dialog,
  DialogContent,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import instance from '../../../providers/axios.config'
import { useLocation, useSearchParams } from 'react-router-dom'
import Notiflix from 'notiflix';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors'
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import useAuthentication from '../../../hooks/useAuthentication'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'

interface IProps {
  open: boolean
  handleClose: () => void
  dataPayload?: any
}

type typeResponse = {
  data?: any,
  message?: string,
  status?: string
}

export const DialogSuccessPaymentImage = (props: IProps) => {
  const { open, handleClose, dataPayload } = props;
  const { isAuthenticated, isLoading } = useAuthentication()
  const user = useSelector((state: RootState) => state.user.usersData)
  const { search } = useLocation()
  const dataParams = new URLSearchParams(search)
  const [searchParams, setSearchParams] = useSearchParams();
  const [linkImage, setLinkImage] = useState<string>('');
  const [categoryState, setCategoryState] = useState<string>('');

  const { t } = useTranslation()



  const fetchOrder = async () => {
    try{
      const dataSize = dataParams?.get('size')
      const category = dataParams?.get('category')
      const orderId = dataParams?.get('order_id')
      const splitData = category?.split('?')
      if (splitData && Number(dataSize)){
        const dataCategory = splitData[0];
        setCategoryState(dataCategory)
        const response = await instance.post(user?.id ? `orders` : `guest/orders`, {...dataPayload, order_id: orderId, category: dataCategory, image_size_id: Number(dataSize)}) as typeResponse;
        setLinkImage(response?.data?.link)
        if(response?.status === 'error') {
          Notiflix.Loading.remove()
          searchParams.delete('session_id')
          searchParams.delete('session_id')
          searchParams.delete('size')
          searchParams.delete('order_id')
          searchParams.delete('category')
          setSearchParams(searchParams)
          setLinkImage('')
          handleClose()
          Notiflix.Report.failure(
            'Error',
            `Can't download image`,
            'OK'
          )
        }
      }
    } catch (e){
      Notiflix.Report.failure(
        'Error',
        `Can't download image error after payment message here`,
        'OK'
      )
    }
  }
  useEffect(() => {
    setTimeout(fetchOrder, 2000)
  }, [])

  const handleDownload = async () => {
    if(linkImage) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        const res = await instance.get(`${linkImage}`, { responseType: 'arraybuffer' }) as any
        if(res.status === 'error') {
          Notiflix.Loading.remove()
          searchParams.delete('session_id')
          searchParams.delete('order_id')
          searchParams.delete('success')
          setSearchParams(searchParams)
          setLinkImage('')
          handleClose()
          Notiflix.Report.failure(
            'Error',
            `Can't download image`,
            'OK'
          )
        }
        let blob = new Blob([res], { type: `image/${categoryState}` }),
          url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        let filename = `fast-download.${categoryState}`; // fallback default
        const contentDisposition = res?.headers['content-disposition'];

        if (contentDisposition && contentDisposition.includes('filename=')) {
          const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
          if (match != null && match[1]) {
            filename = match[1].replace(/['"]/g, ''); // Remove quotes
          }
        }

        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        Notiflix.Loading.remove()
        searchParams.delete('session_id')
        searchParams.delete('size')
        searchParams.delete('category')
        setSearchParams(searchParams)
        setLinkImage('')
        handleClose()
      } catch (e) {
        console.log(e);
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `Can't download image`,
          'OK'
        )
        // handleClose()
      }
    } else {
      searchParams.delete('session_id')
      searchParams.delete('size')
      searchParams.delete('category')
      // setSearchParams(searchParams)
      // setLinkImage('')
      // handleClose()
      Notiflix.Loading.remove()
      Notiflix.Report.failure(
        'Error',
        `Can't download image`,
        'OK'
      )
    }
  }
  return (
    <Dialog open={open}>
        <DialogContent sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, flexDirection: 'column', padding: 0}}>
          <Box sx={{backgroundColor: green[500], width: '100%', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
            <CheckCircleOutlineIcon sx={{ color: 'white', padding: '32px 60px', fontSize: 100 }}/>
          </Box>
          <Box sx={{padding: '32px 60px'}}>
            <Typography fontSize={28} sx={{color: green[700]}}>{t('payment_success')}</Typography>
            <Typography marginLeft={2} fontSize={16}>{t('payment_success_description')}</Typography>
          </Box>
          <LoadingButton
            sx={{margin: 2}}
            onClick={handleDownload}
            endIcon={<SaveIcon />}
            loading={!linkImage}
            loadingPosition="end"
            variant="contained"
          >
            {t('download_image')}
          </LoadingButton>
        </DialogContent>

    </Dialog>
  )
}
